section.resume-section {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
  max-width: 75rem;
  .resume-item {
    .resume-content {}
    .resume-date {
      min-width: none;
    }
  }
}
@media (min-width: 768px) {
  section.resume-section {
    min-height: 100vh;
    .resume-item {
      .resume-date {
        min-width: 18rem;
      }
    }
  }
}
@media (min-width: 992px) {
  section.resume-section {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
