//images
//.gallery {display: flex; flex-wrap: wrap; justify-content: space-between; align-content: space-between;}
.imgCont {border-radius: $border-radius; overflow: hidden; position: relative;  margin: 0.3rem; height: 8.35rem; width: 8.35rem; flex-grow: 2;}
// images - podle počtu se nastaví class less nebo more, čímž se thumbnails zvětší či zmenší
.gallery.less .imgCont {height: 12rem; width: 12rem;}
.gallery.more .imgCont {height: 6rem; width: 6rem;}

.imgCont img {position: relative; left: 50%; object-fit: cover; height: 100%; width: 100%; transform: translateX(-50%);}
.img-label {position: absolute; bottom: 0; left: 0; right: 0; background: rgba($dark, 0.5); color: $white; padding: 0.2rem 0.5rem 0.2rem 0.5rem; width: 100%; font-size: 85%; opacity: 0; transition: .1s ease;}
.imgCont:hover .img-label {opacity: 1;}
@include media-breakpoint-down(md) {.imgCont {height: 7.2rem; width: 7.2rem;}}
@include media-breakpoint-down(lg) {.imgCont {height: 7.2rem; width: 7.2rem;}}

@import "~blueimp-gallery/css/blueimp-gallery.min.css";

.blueimp-gallery a {color: white !important;}