@import "variables.scss";

@import "~bootstrap/scss/bootstrap";


@import '~@fortawesome/fontawesome-free/css/all.css';

@import "variables.scss";
@import "mixins.scss";
@import "global.scss";
@import "nav.scss";
@import "resume-item.scss";
@import "bootstrap-overrides.scss";

@import "gallery";