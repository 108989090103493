.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

a {
  color: $primary;
  &:hover,
  &:focus,
  &:active {
    color: darken($primary, 15%);
  }
}
